import React from "react";
import parse from "html-react-parser";

const InstructionList = ({ instructions }) => {


    instructions = instructions.split("\n");

    return (
        <div>
            <div className="instructions">
                <h6>INSTRUCTIONS</h6>
                <ol>
                    {instructions.map((instruction, i) => (
                        <li key={i}>
                            {instruction}<br />
                        </li>

                    ))}
                </ol>

            </div>

        </div>
    )

}

export default InstructionList;
