import React from "react";
import TopNavBar from "./components/modules/navBar";
import Footer from "./components/modules/footer";

import HomePage from "./components/pages/home";
import LoginForm from './components/pages/loginForm';
import Recipes from "./components/pages/recipes";
import { Route, Routes } from "react-router-dom";
import Contact from "./components/pages/contact";
import RecipesDetails from './components/pages/recipeDetails';
import BlogPostings from "./components/pages/blogPostings";

export default function App() {
  return (
    <React.Fragment>
      <TopNavBar />
      <main className="container" >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<BlogPostings category={'about'} showBanner={false} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dont_sell_my_info" element={<BlogPostings category={'dont-sell-my-info'} showBanner={false} />} />
          <Route path="/food-blog" element={<BlogPostings category={'food-blog'} showBanner={true} />} />
          <Route path="/help" element={<BlogPostings category={'help'} showBanner={false} />} />
          <Route path="/kitchen-hardware" element={<BlogPostings category={'kitchen-hardware'} showBanner={true} blogCount={50} />} />
          <Route path="/kitchen-tips" element={<BlogPostings category={'kitchen-tips'} showBanner={true} blogCount={50} />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/privacy-notice" element={<BlogPostings category={'privacy-notice'} showBanner={false} blogCount={1} />} />

          <Route path="recipes" element={<Recipes />}>
            <Route path="/recipes/:categoryURL" element={<Recipes />} />
          </Route>
          <Route path="/recipe/:recipeURL" element={<RecipesDetails />} />
        </Routes>
      </main>
      <Footer></Footer>
    </React.Fragment >
  );
}
