import React from "react";
import parse from "html-react-parser";
import { Col, Row } from "react-bootstrap";

import "../styleSheets/main.css";
import "bootstrap/dist/css/bootstrap.min.css";

const BlogPost = ({ blogPosts, imageURL, disclaimer }) => {

  if (blogPosts === undefined) {
    return (<div></div>);
  }

  return (
    <div>
      {blogPosts.data.map((blogPost) => (
        <article key={blogPost.id} className="dividerLine">
          <Row>
            <Col xs={4}>
              {blogPost.image_url && <img
                className="rounded-lg"
                src={imageURL + blogPost.image_url}
                alt={blogPost.title}
                style={{ width: "100%" }}
              />}
            </Col>
            <Col xs={7} className="ml-2">
              <h2 className="blogTitle">{blogPost.title}</h2>
              {blogPost.author && <div className="font-weight-bold">
                by <b>{blogPost.author}</b> updated at{" "}
                {blogPost.updated_timestamp}
              </div>}
              {disclaimer && <small>We may be compensated through affiliate links in this posts. All opinions are our own.</small>}
              <section className="mt-2">{parse(blogPost.body)}</section>
            </Col>
          </Row>
        </article>
      ))}
    </div>
  );
};

export default BlogPost;
