import React from "react";
import config from "../../config.json";
import { UseAxiosGet } from "../hooks/httpRequest";
import Loader from "../common/loading";
import BlogPost from "../modules/blogPost";
import PageBanner from "../modules/pageBanner";

export default function BlogPostings({ category, blogCount=100, showBanner=true }) {

  const imageURL = `${config.imageEndpoint}`;
  const apiURL = `${config.apiEndpoint}/blog/${category}/${blogCount}`;
  const blogList = UseAxiosGet(apiURL);
  
  if (blogList.loading || blogList.data === null) {
    return <Loader></Loader>;
  }

  if (blogList.data != null) {
    return (
      <div>
        {showBanner && <PageBanner bannerURL={imageURL + "/${category}-banner.png"} bannerText={category} />}
        <BlogPost blogPosts={blogList} imageURL={imageURL} disclaimer={true}></BlogPost>
      </div>
    );
  }
}