import React from "react";
import BlogPostings from "./blogPostings";

export default function HomePage() {
  return (

    <BlogPostings BlogPostings category={'kitchen-hardware'} showBanner={false} blogCount={1} ></BlogPostings>

    );
};

