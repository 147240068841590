import React from "react";
import { Container, Col, Row } from "react-bootstrap";

const PageBanner = ({ bannerURL, bannerText }) => {

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <img
                        className="rounded-lg"
                        src={bannerURL}
                        alt={bannerText}
                        style={{ width: "100%", height: 160, opacity: 0.3 }}
                    />
                    <div className="bannerText display-1">{bannerText}</div>
                </Col>
            </Row>
        </Container>
    );
}

export default PageBanner;