import React from "react";
import { useParams } from "react-router-dom";
import config from "../../config.json";
import { UseAxiosGet } from "../hooks/httpRequest";
import Loader from "../common/loading";

import IngredientList from "../modules/ingredientList";
import InstructionList from "../modules/instructionList";

export default function RecipesDetails() {
  const { recipeURL } = useParams();
  const apiURL = `${config.apiEndpoint}/recipe/${recipeURL}`;

  let header = {};
  let ingredients = [];
  let instructions = "";


  let recipeDetails = UseAxiosGet(apiURL);

  if (recipeDetails.loading) {
    return <Loader></Loader>;
  }

  if (recipeDetails.data) {
    header = recipeDetails.data.recipe_header;
    ingredients = recipeDetails.data.ingredients;
    instructions = header.instructions;
  }

  const imageURL = `${config.imageEndpoint}${header.image_url}`;

  return (
    <div className="container recipe">
      <div className="row">
        <div className="col-sm-6">
          <h1
            dangerouslySetInnerHTML={{
              __html: header.title,
            }}
          />
          <h5
            dangerouslySetInnerHTML={{
              __html: header.description,
            }}
          />

          <IngredientList ingredients={ingredients} aria-labelledby={'ingredients'} />
          <InstructionList instructions={instructions} aria-labelledby={'Instructiuons'} />

        </div>
        <div className="col-sm-6">
          <img className="w-75" src={imageURL} alt={header.title} />
        </div>
      </div>
    </div>
  );
}
