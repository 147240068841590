import React from "react";

const IngredientList = ({ingredients}) => {
    return (
        <div>
            <h6>INGREDIENTS</h6>
            <div className="ingredients">
                {ingredients.map((ingredient) => (
                    <div key={ingredient.id}>
                        {ingredient.quantity}&nbsp;&nbsp;
                        {ingredient.unit_type}&nbsp;
                        {ingredient.ingredients}
                        {ingredient.comment}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default IngredientList;