import React from "react";
import axios from "axios";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styleSheets/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import config from "../../config.json";

class TopNavBar extends React.Component {
  state = {
    navMenu: "",
  };

  navMenu = (<NavDropdown></NavDropdown>);

  async componentDidMount() {
    //    const serverURL = window.location.href;
    //const serverURL = "192.168.2.50:8080";
    //const apiURL = `${serverURL}/api/v1/nav_menu/recipes`;
    //const apiURL = "http://192.168.2.50:8080/api/v1/nav_menu/recipes";
    const apiURL = `${config.apiEndpoint}/menu/recipes`;
    const { data: menuItems } = await axios.get(apiURL);
    this.createNavMenu(menuItems);
  }

  createNavMenu(menuItems) {
    let navMenu = menuItems.map((menuItem) => (
      <NavDropdown.Item key={menuItem._id} href={`/recipes/${menuItem.url}`}>
        {menuItem.title}
      </NavDropdown.Item>
    ));

    this.setState({ navMenu });
  }

  render() {
    return (
      <Navbar expand="lg" fixed="sticky">
        <Container className="navBar">
          <Navbar.Brand href="/">
            <img
              src="/logo.png"
              width="300"
              height="100"
              className="d-inline-block align-top"
              alt="Chuck's Recipe Box"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Recipes" id="basic-nav-dropdown">
                {this.state.navMenu}
              </NavDropdown>

              <NavDropdown title="The Kitchen" id="basic-nav-dropdown">
                <NavDropdown.Item href="/kitchen-tips">
                  Kitchen Tips
                </NavDropdown.Item>
                <NavDropdown.Item href="/kitchen-hardware">
                  Kitchen Hardware
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/food-blog">Food Blog</Nav.Link>
            </Nav>

            <Nav.Link href="/about-us">About Us</Nav.Link>
            <Nav.Link href="/login">
              <FontAwesomeIcon icon={faUser} /> Login
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
export default TopNavBar;
