/*
  https://github.com/QuentinWatt/React-for-beginners-tutorial-series
*/

import React from "react";
import { useParams, Link } from "react-router-dom";
import config from "../../config.json";
import { UseAxiosGet } from "../hooks/httpRequest";
import Loader from "../common/loading";

export default function Recipes() {
  const { categoryURL } = useParams();
  const apiURL = `${config.apiEndpoint}/recipes/${categoryURL}`;
  const imageURL = `${config.imageEndpoint}`;

  let title = null;
  let recipes = [];

  let recipeList = UseAxiosGet(apiURL);

  if (recipeList.loading) {
    return <Loader></Loader>;
  }

  if (recipeList.data) {
    title = recipeList.data.categoryTitle.title;
    recipes = recipeList.data.recipes;
  }

  return (
    <div>
      <h3>{title} </h3>
      <div className="container">
        <div className="row">
          {recipes.map((recipe) => (
            <div
              key={recipe.id}
              className="col-sm-4 p-3 m-2 recipes card shadow-lg"
            >
              <Link to={`/recipe/${recipe.url}`}>
                <div className="card-body text-center">
                  <img
                    className="img-fluid w-100"
                    src={imageURL + recipe.image_url}
                    alt={recipe.title}
                  />
                </div>
                <div
                  className="h3 card-title"
                  dangerouslySetInnerHTML={{
                    __html: recipe.title,
                  }}
                />
                <div className="h6">
                  {recipe.total_time && (
                    <div>
                      Cook time: {recipe.total_time}&nbsp;&nbsp;{recipe.level}
                    </div>
                  )}
                </div>
                <div
                  className="h6"
                  dangerouslySetInnerHTML={{
                    __html: recipe.description,
                  }}
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
