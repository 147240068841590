import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styleSheets/main.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <Container className="footer">
      <div className="row w-100 inline">
        <div className="container text-center social_media">
          <Link to={"/facebook"} title="Facebook">
            <FontAwesomeIcon icon={faFacebook} />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/twitter"} title="Twitter">
            <FontAwesomeIcon icon={faTwitter} />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/instagram"} title="Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/pinterest"} title="Pinterest">
            <FontAwesomeIcon icon={faPinterest} />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/youtube"} title="YouTube">
            <FontAwesomeIcon icon={faYoutube} />
          </Link>
        </div>
      </div>
      <div className="row mt-3 w-100">
        <div className="col-sm-3">
          <ul>
            <li>
              <Link to={"/about-us"}>About</Link>
            </li>
            <li>
              <Link to={"/contact"}>Contact</Link>
            </li>

            <li>
              <Link to={"/help"}>Help / FAQ</Link>
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <ul>
            <li>
              <Link to={"/dont_sell_my_info"}>Don't Sell My Info</Link>
            </li>
            <li>
              <Link to={"/privacy-notice"}>Privacy Notice</Link>
            </li>
            <li>
              <Link to={"/visitor-agreement"}>Visitor Agreement</Link>
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <ul>
            <li>
              <Link to={"/login"}>Login</Link>
            </li>
            <li>
              <Link to={"/news-letter"}>News Letter</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row w-100">
        <div className="copyright">
          Copyright&nbsp;
          <FontAwesomeIcon icon={faCopyright} />
          &nbsp;
          {year} Chuck's Recipe Box &nbsp;&nbsp;&nbsp;All Rights Reserved.
        </div>
      </div>
    </Container>
  );
};

export default Footer;
